import UnityWebgl from 'unity-webgl';

const version = process.env.VUE_APP_UNITY_VERSION;
const backendUrl  = process.env.VUE_APP_BASE_URL;

const Unity = new UnityWebgl({
  loaderUrl: `/unity_client/Build/${version}.loader.js`,
  dataUrl: `/unity_client/Build/${version}.data`,
  frameworkUrl: `/unity_client/Build/${version}.framework.js`,
  codeUrl: `/unity_client/Build/${version}.wasm`,
  backendUrl: `${backendUrl}`
});

export default Unity;
