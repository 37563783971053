<template>
  <div class="wrapper">
    <router-link
        to="/"
        v-if="this.authorized && this.$route.path != '/' && !status"
        class="back"
    >
      back
    </router-link>
    <router-view v-if="this.authorized && !status"></router-view>
    <div class="chess__blocker" v-if="status === 'play'">
      Rotate your phone to landscape
    </div>
    <div v-if="!this.authorized" class="card">
      <LoginForm/>
    </div>
    <div
        v-else
        v-show="status === 'play'"
        class="chess"
    >
      <VueUnity
          :unity="unity"
          :width="unityWidth"
          :height="unityHeight"
      />
    </div>
  </div>
</template>

<script>
import VueUnity from 'unity-webgl/vue';
import LoginForm from "@/components/LoginForm";
import Unity from "@/./unity.js"


export default {
  name: 'App',
  components: {
    VueUnity,
    LoginForm
  },
  async mounted() {
    // const bgSound = document.createElement('audio');
    // bgSound.loop = true;
    // bgSound.src = '/sounds/bg.mp3'
    // const app = document.getElementById('app')
    // app.addEventListener("click", () => bgSound.play())
    if (!this.authorized || !this.$store.state.auth.jwt) {
      if (this.$cookies.get('jwt')) {
        this.axios.defaults.headers = {
          'Authorization': `bearer ${this.$cookies.get('jwt')}`
        };
        await this.$store.dispatch('TRY_AUTH', this);
      } else if (this.$cookies.get('token')) {
        this.$store.dispatch('SET_TOKEN', this.$cookies.get('token'))
        this.$store.dispatch('TRY_AUTH', this);
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token')
        this.$store.dispatch('SET_TOKEN', token)
        this.$store.dispatch('TRY_AUTH', this);
      }
    }

    // const unity_version = process.env.VUE_APP_UNITY_VERSION;
    // fetch(`/unity_client/Build/${unity_version}.data`, {credentials: "same-origin", cache: "force-cache"});
    // fetch(`/unity_client/Build/${unity_version}.wasm`, {credentials: "same-origin", cache: "force-cache"});

    Unity.on('screenExpand', this.expandScreen);
    Unity.on('backToPVP', this.backToPVP)
  },
  data() {
    return {
      unityWidth: "896",
      unityHeight: "414",
      fullScreen: false
    }
  },
  methods: {
    expandScreen() {
      if (!this.fullScreen) {
        this.fullScreen = true
        Unity.unityInstance.SetFullscreen(true)
      } else {
        this.fullScreen = false
        Unity.unityInstance.SetFullscreen(false)
      }
    },
    backToPVP() {
      this.$store.dispatch('SET_STATUS', undefined)
      this.$router.push({ path: '/pvp' })
      this.fullScreen = false
      Unity.unityInstance.SetFullscreen(false)
    }
  },
  computed: {
    authorized() {
      return this.$store.state.auth.authorized
    },
    unity() {
      return Unity;
    },
    status() {
      return this.$store.state.pvp.status;
    }
  }
}
</script>

<style lang="scss">
@import "fonts/font.css";

#app {
  font-family: agencyfb_reg, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}

html, body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  letter-spacing: 1px;
}

.wrapper {
  width: 100%;
  min-height: 100%;
  height: auto;
  background: url('./assets/bg.jpg') no-repeat 50%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  background: url('assets/menu_wrapper.png');
  background-size: 100% 100%;
  padding: 50px;
  color: yellow;

  &-landscape {
    background: url('assets/card-landscape.png');
    background-size: 100% 100%;
    padding: 50px;
  }
}

.back {
  position: absolute;
  right: 1em;
  top: 1em;
  padding: 0.5em 2em;
  background: url('./assets/category.png') no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  text-transform: uppercase;
  color: yellow;
  font-weight: 600;
  transition: ease filter 0.3s;
  text-decoration: none;

  &:hover {
    filter: saturate(0.5);
  }
}

.chess {
  height: 100%;
  width: 100%;
  max-height: 620px;
  max-width: 1100px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__blocker {
    display: none;
  }
}

@media (max-width: 720px) {
  .wrapper {
    min-height: 100%;
    height: auto;
  }
  .chess {
    display: none;

    &__blocker {
      font-size: 2em;
      font-weight: 600;
      display: block;
      width: 100%;
      padding: 2em;
      margin: 1em;
      background: green;
    }
  }
}
</style>
